exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/blog/{markdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-blog-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-case-studies-index-js": () => import("./../../../src/pages/case-studies/index.js" /* webpackChunkName: "component---src-pages-case-studies-index-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-events-product-index-js": () => import("./../../../src/pages/events-product/index.js" /* webpackChunkName: "component---src-pages-events-product-index-js" */),
  "component---src-pages-for-travel-suppliers-index-js": () => import("./../../../src/pages/for-travel-suppliers/index.js" /* webpackChunkName: "component---src-pages-for-travel-suppliers-index-js" */),
  "component---src-pages-help-help-support-js": () => import("./../../../src/pages/help/help-support.js" /* webpackChunkName: "component---src-pages-help-help-support-js" */),
  "component---src-pages-impala-events-index-js": () => import("./../../../src/pages/impala-events/index.js" /* webpackChunkName: "component---src-pages-impala-events-index-js" */),
  "component---src-pages-impala-events-pricing-js": () => import("./../../../src/pages/impala-events/pricing.js" /* webpackChunkName: "component---src-pages-impala-events-pricing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mastering-travel-index-js": () => import("./../../../src/pages/mastering-travel/index.js" /* webpackChunkName: "component---src-pages-mastering-travel-index-js" */),
  "component---src-pages-pricing-index-js": () => import("./../../../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-reluctant-travel-agent-index-js": () => import("./../../../src/pages/reluctant-travel-agent/index.js" /* webpackChunkName: "component---src-pages-reluctant-travel-agent-index-js" */),
  "component---src-pages-the-impala-service-accomodation-negotiation-js": () => import("./../../../src/pages/the-impala-service/accomodation-negotiation.js" /* webpackChunkName: "component---src-pages-the-impala-service-accomodation-negotiation-js" */),
  "component---src-pages-the-impala-service-airline-ground-transport-js": () => import("./../../../src/pages/the-impala-service/airline-ground-transport.js" /* webpackChunkName: "component---src-pages-the-impala-service-airline-ground-transport-js" */),
  "component---src-pages-the-impala-service-group-travel-js": () => import("./../../../src/pages/the-impala-service/group-travel.js" /* webpackChunkName: "component---src-pages-the-impala-service-group-travel-js" */),
  "component---src-pages-the-impala-service-index-js": () => import("./../../../src/pages/the-impala-service/index.js" /* webpackChunkName: "component---src-pages-the-impala-service-index-js" */),
  "component---src-pages-travel-audit-index-js": () => import("./../../../src/pages/travel-audit/index.js" /* webpackChunkName: "component---src-pages-travel-audit-index-js" */),
  "component---src-pages-why-impala-index-js": () => import("./../../../src/pages/why-impala/index.js" /* webpackChunkName: "component---src-pages-why-impala-index-js" */)
}

